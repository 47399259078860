.buttons-container {
  height: 64px;
  opacity: 1;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  margin-left: -1px;
  margin-top: -1px;
}

.chart-types-buttons-container{
  display: flex;
  align-items: center;
  justify-content: start;
}