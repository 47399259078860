.header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  margin-left: -1px;
  position: relative;
}

.left-section {
  display: flex;
  align-items: center;
}

.header-text {
  font-family: 'Inter', sans-serif;
  font-size: 24px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.02em;
  text-align: left;
}

.menu-toggle {
  padding-right: 10px;
}

.right-section {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.language-switcher {
  margin-right: 10px;
  cursor: pointer;
}

.user-profile-picture {
  padding-right: 0px;
}

.nav-language-dropdown {
  position: absolute;
  top: 64px;
  right: 50px;
  background-color: white;
  border-radius: 8px;
  padding: 5px;
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.nav-items-container {
  display: flex;
  flex-direction: column;
}

.nav-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.nav-item:last-child {
  margin-bottom: 0;
}

.flag-img-container {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.flag-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.menu-title {
  font-size: 14px;
}