:root{
    --ei-green: #4caf50;
    --ei-font-family: 'Inter', sans-serif;
    --white: #FFFFFF;
    --ei-gray: #e3e3e3;
}
/* CustomizeButton.js */
.customize-button {
    display: flex;
    align-items: center;
    font-family: var(--ei-font-family);
    font-size: 14px;
}

.customize-button:hover, .customize-button.selected, .font-weight-600 {
    font-weight: 600;
}

.customize-tabs button, .font-weight-400{
    font-weight: 400;
}

.customize-button-icon {
    --primaryA1: var(--A1);
    padding-left: 5px;
}

/* CustomizePopup.js */
.customize-menu {
    width: 320px;
    height: auto;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--ei-gray);
    background-color: var(--white);
    margin: -1px 0 -1px 0;
    margin-left: 20px;
    flex-shrink: 0;
    font-family: var(--ei-font-family);
}

.close-button {
    cursor: pointer;
    padding-top: 26px;
    padding-right: 18px;
    border: none;
    background: none;
}

.customize-menu-header{
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    font-size: 20px;
}

.tab-content-container {
    margin-top: 8px;
}

.customize-tabs {
    display: flex;
    justify-content: start;
    position: relative;
}

.customize-tabs button {
    border: none;
    background: none;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    position: relative;
    font-size: 16px;
    margin-left: 5px;
    margin-right: 5px
}

.customize-tabs button.active{
    --primaryA1: var(--A1);
    color: var(--primaryA1);
    font-weight: 600;
}

.divider{
    border-top: 1px solid var(--ei-gray);
}

/* flourish undeneath selected Customize tab */
.customize-tabs button.active::after {
    --primaryA1: var(--A1);
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 5px;
    background-color: var(--primaryA1);
    border-radius: 30px 30px 0 0;
}

/* all popup tabs */
.tab-content {
    padding: 10px;
}

.customization-full-width{
    width: 100%;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin: 20px 5px 5px 5px;
    border-radius: 5px;
    font-size: 14px; 
}

.customization-half-width{
    width: 50%;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin: 10px 5px 5px 5px;
    border-radius: 5px;
    font-size: 14px;
}

/* SettingsTab.js */
.settings-tab-content{
    font-size: 16px;
}

/* Settings input */

.number-input-div {
    display: flex;
    align-items: center;
    justify-content:space-between;
    border: 1px solid var(--ei-gray);
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    margin-top: 8px;
  }
  
  .number-input-div input {
    text-align: center;
    border: none;
    font-size: 18px;
    width: 50px;
    padding: 5px;
    background-color: var(--white);
  }
  
  .number-input-div input:focus {
    outline: none;
  }
  
  .number-input-div button {
    background-color: var(--white);
    border: none;
    padding: 5px 6px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .number-input-div button:hover {
    background-color: var(--ei-gray);
  }
  
  .decrement {
    border-right: 1px solid var(--ei-gray);
  }
  
  .increment {
    border-left: 1px solid var(--ei-gray);
  }

  input[type=text] {
    width: 300px;
 }

  /* End settings input style */

.apply-button {
    --primaryA1: var(--A1);
    --primaryA2: var(--A2);
    background-color: var(--primaryA1);
    color: var(--primaryA2);
    
}

.clear-button {
    background-color: #ccc;
}

.button-container{
    display: flex;
    justify-content: center;
}

/* ChartsTab.js */
.chart-options {
    display: flex;
    flex-direction: column;
    max-height: 700px;
    overflow-y: auto; 
}

.chart-item {
    padding: 5px;
}

.chart-item input {
    margin-right: 10px;
}

.chart-item.selected{
    background-color: #F3F4F6;
    font-weight: 700;
}

/* Charts checkbox*/
.checkbox {
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox-label {
    flex-grow: 1;
    font-size: 16px;
    color: #1f2937;
    margin-right: 10px;
    white-space: normal;
    overflow:auto;
    word-wrap: break-word;
}

.checkbox-input {
    position: relative;
    width: 20px;
    height: 20px;
    appearance: none;
    background-color: #f5f5f5;
    border: 2px solid #1f2937;
    border-radius: 7px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex-shrink: 0;
    margin-left: 10px;
}

.checkbox-input:checked {
    background-color: #1f2937;
    border-color: #1f2937;
}

/* Checkmark styling */
.checkbox-input:checked:after {
    content: "";
    position: absolute;
    top: 3px;
    left: 6px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}



/* PortfolioTab.js */
.update-portfolio{
    --primaryA1: var(--A1);
    --primaryA2: var(--A2);
    background-color: var(--primaryA1);
    color: var(--primaryA2)
}

.save-custom-portfolio, .portfolio-name-input, .cancel-submit-name-button{
    background-color: var(--ei-gray);
    color: #000000;
}

.allocation-control {
    border: 1px solid #079455;
    border-radius: 5px;
    padding: 0;
    margin: 0;
}

.allocation-control.good{
    border: 1px solid #079455;
}

.allocation-control.warning{
    border: 1px solid #DC6803;
}

.allocation-control.bad{
    border: 1px solid #D92D20;
}

.allocation-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 10px 15px;
}

.input-container {
    display: flex;
    align-items: center;
    width: 80px;
    position: relative;
}

.allocation-item input {
    width: 100%;
    height: 2.5em;
    padding: 0 30px 0 10px;
    text-align: center;
    border: 1px solid var(--ei-gray);
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    font-weight: 500;
}

.allocation-item .percentage-symbol {
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #000000;
    font-size: 1em;
}

.allocation-item input:focus {
    outline: none;
    border-color: var(--ei-green);
}

.total-allocation{
    background-color: #ECFDF3 
}

.rounded-corners{
    border-radius: 10px;
}

.total-allocation-container {
    padding: 15px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ECFDF3;
    border-radius: 5px;
    box-sizing: border-box;
    margin: 0;
}

.total-allocation-text{
    font-size: 16px;
}

.total-allocation-container.good{
    background-color: #D5F0D5;
}

.total-allocation-container.warning{
    background-color: #FEF0C7; 
}

.total-allocation-container.bad{
    background-color: #FEE4E2 ;
}

.total-allocation-slash.good{
    color: #BCCCBC;
}

.total-allocation-slash.warning{
    color: #FFD564;
}

.total-allocation-slash.bad{
    color: #FFB7B2;
}

.total-allocation-var.good{
    color: #079455;
}

.allocation-error-message.warning, .total-allocation-var.warning, .triangle-icon-warning{
    color: #DC6803;
}

.allocation-error-message.bad, .total-allocation-var.bad, .triangle-icon-bad, .error-message{
    color: #D92D20;
}

.allocation-error-message{
    display: flex;
    align-items: center;
    font-size: 14px;
    justify-content: center;
}

button:disabled {
    background-color: #ccc;
    color: #666;
    opacity: 0.6;
  }


/* CustomDropdown */ 
.portfolio-dropdown-container {
    position: relative;
    width: 100%;
    font-family: var(--ei-font-family);
    margin-bottom: 5px;
  }
  
  .dropdown {
    position: relative;
    cursor: pointer;
  }
  
  button.dropdown-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border: 1px solid var(--ei-gray);
    padding: 10px 15px;
    border-radius: 8px;
    font-weight: 600;
    font-family: var(--ei-font-family);
    font-size: 16px;
    background-color: var(--white);
  }
  
  .chevron {
    transition: transform 0.3s ease;
  }
  
  .chevron.rotate {
    transform: rotate(180deg);
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--white);
    border: 1px solid var(--ei-gray);
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    margin-top: 1px;
    padding: 10px 0;
    z-index: 1;
    max-height: 100vh;
    overflow-y: auto;  
  }
  
  .dropdown-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .dropdown-option:hover {
    background-color: var(--ei-gray);
  }
  
  .option-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid black;
    display: inline-block;
    position: relative;
  }
  
  .option-icon.filled {
    background-color: black;
    border: 2px solid black;
  }
  
  .option-icon.filled::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px; /* Size of the white circle */
    height: 5px; /* Size of the white circle */
    background-color: white;
    border-radius: 50%;
  }
  
  .option-icon.outlined {
    background-color: transparent;
  }
  

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}