.page-content-card {
  width: calc(100% - 36px);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin: 17px;
  position: relative;
}

.pagecontent-card-header-container {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  align-self: start;
  background-color: #fff;
  width: 100%;
  border-bottom: 1px solid #e3e3e3;
}

.pagecontent-card-title {
  margin: 10px 0 0 0;
  padding: 0 0 0 20px;
  font-size: 20px;
  font-weight: 600;
  color: #1F2A37;
  align-self: start;
}

.pagecontent-card-subtitle {
  margin: 10px 0 10px 0;
  padding: 0 0 0 20px;
  font-size: 14px;
  font-weight: 400;
  color: #4D5761;
  align-self: start;
}

.pagecontent-card-footer-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  align-self: start;
  background-color: #fff;
  width: 100%;
}

.last-updated-date-text {
  font-size: 21px;
  color: #4D5761;
  background-color: #fff;
  margin: 15px 0px 10px 20px;
  align-self: start;
}

.last-updated-date-text span {
  font-size: 21px;
  color: #101828;
  align-self: start;
}

.pagecontent-card-header-container {
  display: flex;
  flex-direction: column;
}

.pagecontent-card-subtitle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}

.pagecontent-card-subtitle {
  margin-right: auto;
}

.pagecontent-card-icons {
  display: flex;
  align-items: center;
}

.presentation-icon,
.export-icon {
  margin-top: -30px;
  cursor: pointer;
}

.presentation-icon {
  margin-right: 10px;
}

/* Export Menu */

.export-menu-item {
  display: flex;
  align-items: center;
  padding: 8px !important;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.export-menu-item .icon {
  margin-right: 8px;
}

.save-view {
  font-weight: bold;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.export-menu-item:hover {
  background-color: #f0f0f0;
}

