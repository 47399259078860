.world-events-menu {
  width: 320px;
  height: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid #e3e3e3;
  background-color: #fff;
  margin: -1px 0 -1px 0;
}

.world-events-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding-left: 10px;
  padding-right: 30px;
  border-bottom: 1px solid #e3e3e3;
}

.world-events-menu-header h2 {
  margin: 0;
  padding-top: 20px;
}

.world-events-menu-header span {
  margin: 0;
  padding-top: 20px;
  padding-right: 10px;
}

.close-button {
  cursor: pointer;
}

.close-button img {
  height: 14px;
  width: 14px;
}


.world-events-menu-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  padding: 15px;
  border-radius: 8px;
}

.world-events-menu-filter.selected {
  background-color: #F3F4F6;
}

.filter-title {
  margin-right: auto;
}

.filter-checkbox {
  display: none;
}

.filter-checkbox:checked+.custom-checkbox {
  background-color: #1F2A37;
  color: #fff;
}

.custom-checkbox::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-color: transparent;
}

.world-events-menu-content {
  padding: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 5px;
}

.filter-checkbox {
  display: none;
}

.custom-checkbox {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url('../../images/tabler_square-rounded.svg') no-repeat center center;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.custom-checkbox.checked {
  background: url('../../images/checkmark_selected.svg') no-repeat center center !important;
  background-size: cover !important;
}

/* Optional: Adjust the position of the checkbox if needed */
.custom-checkbox::before {
  content: '';
  display: inline-block;
  width: 100%;
  height: 100%;
  background-size: contain;
}