.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #fff;
}

.layout-container {
  display: flex;
  flex: 1;
}

.sidemenu-container {
  display: flex;
  flex: 1;
}

.pagecontent-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #F3F4F6;
}

.main-content {
  display: flex;
  flex: 1;
}

.pagecontent-container>.main-content>.world-events-menu {
  margin-left: 20px;
  flex-shrink: 0;
}


.layout-container {
  position: relative;
}

.RecessionsChart {
  position: absolute;
  z-index: 0;
}

.sidemenu-wrapper {
  position: relative;
  display: flex;
}

.sidemenu-container {
  position: relative;
}

.side-menu-button {
  position: absolute;
  right: 17px;
}

.loading-indicator {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 5px;
  z-index: 1000;
  font-size: 1.5em;
}

.chart-type-customize-button-container{
  display: flex;
  justify-content: space-between;
}

.modebar{
 grid-column: 1;
}

.grid-1r-2c{
  display: grid;
  grid-template-columns: 5% 95%;
}

.grid-1r-3c{
  display: grid;
  grid-template-columns: 5% 70% 25%;
}

.page-content-card{
  grid-column: 2;
}

button.unstyled-button{
  border: none;
  background: none;
  padding: 0px;
}

/* Position SaveViewMenu in the top-right corner */
.save-view-menu-container {
	position: absolute;
	top: 85px;
	right: 0;
	z-index: 1000; /* Ensure it appears above other elements */
}

/* Optional: Style for SaveViewMenu */
.save-view-menu-container > * {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
	background-color: white;
	padding: 8px;
	border-radius: 4px;
}

.loading-container{
  display:flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner {
  width: 48px;
  height: 48px;
  border: 5px solid #4caf50;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  margin-top: 20px;
  animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
  } 

  .text-A1 {
    color: var(--A1);
  }