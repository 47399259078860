.chart-type-button {
  border: 2px solid #e0e0e0;
  background-color: #fff;
  color: #3f4853;
  padding: 8px 16px 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
}

.chart-type-button:hover,
.chart-type-button.selected {
  --A2: none;
  border: 2px solid var(--A1);
  color: var(--A1);
  background-color: var(--A2);
}