.custom-modebar-item-container {
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  cursor: pointer;
  background-color: var(--fill-color);
  border: none;
}
.main-content .modebar{
  display: none !important;
}
.main-content.expanded .modebar{
  display: flex !important;
}
.modebar-toggle-btn{
  margin-top: 350px;
}

.modebar {
  right: auto !important;
  left: -70px !important;
  top: -77px !important;
}

.modebar,
.modebar .modebar-btn {
  display: flex !important;
  flex-direction: column !important;
}

.modebar .modebar-btn:hover .icon {
  background-color: transparent !important;
}

.modebar .modebar-btn {
  width: 46px !important;
  height: 46px !important;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
}

.modebar .modebar-btn svg {
  width: 22px;
  height: 22px;
}

.modebar .modebar-btn .icon path {
  fill: var(--primaryA1) !important;
}

.modebar .modebar-group {
  opacity: 1 !important;
  background-color: transparent !important;
}

.modebar .modebar-group:last-child {
  display: none !important;
}

.modebar .modebar-btn.active {
  background-color: var(--primaryA1);
}

.modebar .modebar-btn:hover {
  background-color: #ffffff;
}
.modebar .modebar-btn.active .icon path {
  fill: #fff !important;
}
.modebar .modebar-btn:hover .icon path {
  fill: var(--primaryA1) !important;
}

.custom-modebar {
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.icon,
button.icon {
  --primaryA2: none;
  --primaryA1: var(--A1);
  background-color: var(--primaryA2);
  border-radius: 3px;
}

.icon:hover,
button.icon:hover {
  --primaryA2: var(--A2);
  --primaryA1: var(--A1);
  background-color: var(--primaryA2);
}

.icon.selected {
  --primaryA2: var(--A1);
  --primaryA1: var(--A2);
  background-color: var(--primaryA2);
}

.modebar-menu-button-expanded {
  grid-row: 2;
  margin-top: 15px;
}

.modebar-menu-button-collapsed {
  grid-row: 2;
}

.custom-mode-bar-element {
  grid-row: 1;
}

.modebar-container-div {
  height: 375px;
  width: 56px;
  display: grid;
  grid-template-rows: 325px 24px;
  grid-template-columns: 1fr;
  gap: 10px;
  background-color: #f3f4f6;
}

.modebar-container-div-collapsed {
  height: 375px;
  width: 24px;
  display: grid;
  grid-template-rows: 325px 24px;
  grid-template-columns: 1fr;
  gap: 10px;
}

.disabled {
  --primaryA1: #999999 !important;
  --primaryA2: #999999 !important;
  cursor: not-allowed !important;
}