.footer {
  background-color: #fff;
  margin: 0 0 -2px -1px; ;
  padding: 12px 16px;
  display: flex;
  align-items: stretch;
  border: 1px solid #e0e0e0;
}

.disclaimer-section {
  flex: 1;
  text-align: left;
  font-size: 8px;
  line-height: 1.5;
}

.morningstar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

.morningstar-logo {
  fill: #757575;
  width: 150px;
}